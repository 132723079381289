import React, { useCallback, useEffect, useState } from 'react';
import { trackingClient } from 'clients';
import { CAR_TRACKING_PAGE_SIZE } from 'clients/TrackingClient';
import { HTTPError } from 'clients/HTTPClient';
import { CarTracking, CarTrackingStatus } from 'types/CarTracking';
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg';
import { ReactComponent as Lines } from 'assets/icons/lines.svg';
import {
    dateTimeWithTimezone,
    dateWithTimeZone,
    getYearMonthDayFromString,
    minutesToStringRepresentation,
} from 'utils/helpers';
import AGTAbleWrapper from 'components/AGTable/AGTableWrapper';
import InfoTooltip from 'components/Dashboard/SpotCard/InfoTooltip/InfoTooltip';
import SingleDateSelector from 'components/SingleDateSelector/SingleDateSelector';
import AGSimpleSelect from 'components/AGSelect/AGSimpleSelect/AGSimpleSelect';
import SearchInput from 'components/AGForm/Inputs/SearchInput/SearchInput';
import Loader from 'components/Loader/Loader';
import Pagination from 'components/Pagination/Pagination';
import AGMessage from 'components/AGMessage/AGMessage';
import styles from './DriverLog.module.css';
import { XDaysAgo } from 'components/AGDatePicker/utils';
import { AuthorizedView, Roles } from 'components/Utilities/AuthorizedView';

type DriverLogProps = {
    spotUuid: string;
    timezone: string;
    filters: {
        date: string;
        status: CarTrackingStatus | '';
        currentSearchTerm: string;
        page: string;
        searchTerm: string;
    };
    urlUpdater: Record<'date' | 'status' | 'page', (value: string | null) => void> & {
        searchTerm: (search: string) => void;
    };
};

const STATUS_OPTIONS = [
    { value: '', label: 'All types' },
    { value: CarTrackingStatus.ENFORCED, label: 'Enforced' },
    { value: CarTrackingStatus.GRACE_PERIOD, label: 'Grace period' },
    { value: CarTrackingStatus.RENTER, label: 'Renter' },
    { value: CarTrackingStatus.VIOLATOR, label: 'Violator' },
    { value: CarTrackingStatus.VISITOR, label: 'Visitor' },
];

function DriverLog({ spotUuid, filters, urlUpdater, timezone }: DriverLogProps): JSX.Element {
    const [carTrackingEvents, setCarTrackingEvents] = useState<CarTracking[]>([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [totalPages, setTotalPages] = useState(1);

    const today = new Date();

    const getCarTrackingEvents = useCallback(
        async function _getCarTrackingEvents(
            date: string,
            page: number,
            search: string,
            status: CarTrackingStatus | ''
        ) {
            setLoading(true);
            try {
                const { month, day, year } = getYearMonthDayFromString(date);
                const response = await trackingClient.getCarTrackingEvents(spotUuid, {
                    page,
                    start: dateWithTimeZone(timezone, year, month - 1, day, 0, 0).getTime() / 1000,
                    end: dateWithTimeZone(timezone, year, month - 1, day, 23, 59).getTime() / 1000,
                    plate: search,
                    status,
                });
                setTotalPages(Math.ceil(response.count / CAR_TRACKING_PAGE_SIZE));
                setCarTrackingEvents(response.results);
            } catch (e) {
                setTotalPages(1);
                if (HTTPError.isHTTPError(e)) {
                    setErrorMessage(e.message);
                } else {
                    setErrorMessage(e?.toString() || 'Something went wrong');
                }
            } finally {
                setLoading(false);
            }
        },
        [spotUuid, timezone]
    );

    useEffect(() => {
        getCarTrackingEvents(filters.date, Number(filters.page), filters.searchTerm, filters.status);
    }, [getCarTrackingEvents, filters.date, filters.page, filters.searchTerm, filters.status]);

    function searchRentals(event: React.ChangeEvent<HTMLInputElement>) {
        urlUpdater.searchTerm(event.target.value);
        urlUpdater.page('1');
    }

    function selectDate(date: Date | undefined) {
        urlUpdater.date(date?.toLocaleDateString() || '');
        urlUpdater.page('1');
    }

    function selectStatus(status: string) {
        urlUpdater.status(status);
        urlUpdater.page('1');
    }

    return (
        <div className="visibility-section">
            <div className={styles.header}>
                <h3>Driver Log</h3>
                <div className={styles.filters}>
                    <SingleDateSelector
                        date={filters.date ? new Date(filters.date) : undefined}
                        onSave={selectDate}
                        placeHolder="Select Start Date"
                        allowDeSelect={false}
                        latestAvailableDate={today}
                        earliestAvailableDate={XDaysAgo(today, 6)}
                        noIcon
                        dropdownWidth="280px"
                        disabled={loading}
                    />
                    <AGSimpleSelect
                        selected={filters.status}
                        onSelect={selectStatus}
                        options={STATUS_OPTIONS}
                        icon={<Lines />}
                        width="200px"
                        disabled={loading}
                    />
                    <SearchInput
                        onChange={searchRentals}
                        value={filters.currentSearchTerm}
                        placeholder="Search by plate"
                        autoFocus
                        type="text"
                        className={styles.search}
                        width="200px"
                        name="plate"
                        disabled={loading}
                    />
                </div>
            </div>
            {errorMessage && <AGMessage color="error" title={errorMessage} />}
            <AGTAbleWrapper>
                <thead>
                    <tr>
                        <th>License Plate and State</th>
                        <th>
                            <CameraIcon />
                        </th>
                        <th>Type</th>
                        <th>Date/Time In</th>
                        <th>Date/Time Out</th>
                        <th>Duration</th>
                        <th>All-time rentals</th>
                        <AuthorizedView allowed={[Roles.Superuser]}>
                            <th>
                                All-time Visits
                                <InfoTooltip
                                    text={`This is the total number of visits as of today, ${today.toLocaleDateString()}`}
                                />
                            </th>
                        </AuthorizedView>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={6}>
                                <Loader />
                            </td>
                        </tr>
                    ) : carTrackingEvents.length === 0 ? (
                        <tr>
                            <td colSpan={6}>Driver log information will show up here.</td>
                        </tr>
                    ) : (
                        carTrackingEvents.map((trackingEvent, i) => {
                            const { entrance_timestamp, exit_timestamp } = trackingEvent;
                            const img =
                                trackingEvent.entrance_license_plate_image || trackingEvent.exit_license_plate_image;
                            const durationInMinutes =
                                entrance_timestamp && exit_timestamp
                                    ? Math.round((exit_timestamp - entrance_timestamp) / 60)
                                    : undefined;
                            return (
                                <tr key={i}>
                                    <td>
                                        {trackingEvent.plate}
                                        {trackingEvent.state ? ` - ${trackingEvent.state}` : ''}
                                    </td>
                                    <td>
                                        {img && (
                                            <img
                                                src={img}
                                                alt=""
                                                className={styles.plateImage}
                                                onError={(e) => {
                                                    e.currentTarget.className = styles.brokenImage;
                                                }}
                                            />
                                        )}
                                    </td>
                                    <td className={styles.status}>{trackingEvent.status}</td>
                                    <td>
                                        {entrance_timestamp
                                            ? dateTimeWithTimezone(new Date(entrance_timestamp * 1000), timezone)
                                            : ''}
                                    </td>
                                    <td>
                                        {exit_timestamp
                                            ? dateTimeWithTimezone(new Date(exit_timestamp * 1000), timezone)
                                            : ''}
                                    </td>
                                    <td>
                                        {!durationInMinutes
                                            ? ''
                                            : durationInMinutes > 30
                                            ? minutesToStringRepresentation(durationInMinutes)
                                            : 'Under 30min'}
                                    </td>
                                    <td>{trackingEvent.rental_count}</td>
                                    <AuthorizedView allowed={[Roles.Superuser]}>
                                        <td>{trackingEvent.visit_count}</td>
                                    </AuthorizedView>
                                </tr>
                            );
                        })
                    )}
                </tbody>
                {totalPages > 1 && !loading && (
                    <tfoot>
                        <tr>
                            <td colSpan={6}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Pagination
                                        currentPage={Number(filters.page)}
                                        onPageChange={urlUpdater.page}
                                        totalPages={totalPages}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </AGTAbleWrapper>
        </div>
    );
}

export default DriverLog;
